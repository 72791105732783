import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import parse from 'html-react-parser';
import {isMobile, isTablet} from 'react-device-detect';

import Clients from "../components/Clients";
import Loading from "../components/Loading";

import {loadLimitedCaseStudies, loadTestimonials} from "../common/Common";

import homeHeader from '../assets/images/home-header.jpg';
import aboutUs from '../assets/images/about-us.png';
import aboutUsMobile from '../assets/images/about-us-mobile.png';
import connectWithUs from '../assets/images/connect-with-us.png';

import '../assets/css/Home.css';
import '../assets/css/AboutUs.css';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonials: [],
            caseStudies: [],
            loading: true,
        };
    }

    componentDidMount() {
        loadTestimonials().then(data => {
            this.setState({testimonials: data});
        }).catch(() => null);
        loadLimitedCaseStudies(4).then(data => {
            this.setState({caseStudies: data});
        }).catch(() => null);
        setTimeout(() => {
            this.setState({loading: false});
        }, 2500);
    }

    testimonialBody = (testimonials) => {
        let returnBody = [];
        testimonials.forEach((testimonial, index) => {
            returnBody.push(
                <div className="testimonial" key={index}>
                    <div className="row">
                        <div className="col-3">
                            <img src={testimonial.profile_image}
                                 alt={testimonial.name}
                                 style={{borderRadius: 32, width: 65, height: 65}}/>
                        </div>
                        <div className="col-9">
                            <p style={{color: '#545666'}}>{testimonial.message}</p>
                            <h3 className="testimonial-name">{testimonial.name}</h3>
                            <p className="testimonial-company">{testimonial.designation}, {testimonial.company_name}</p>
                        </div>
                    </div>
                </div>
            );
        });
        return returnBody;
    }

    caseStudyBody = (caseStudies) => {
        let body = [];
        caseStudies.forEach((caseStudy, index) => {
            body.push(
                <div className="col" key={index}>
                    <div style={{position: 'relative', width: '17vw', height: '27vh'}}>
                        <NavLink to={"/case-studies/#" + caseStudy.title.replace(/\s+/g, '-')}>
                            <img src={caseStudy.image}
                                 alt={caseStudy.title}
                                 style={{borderRadius: '32px', width: '100%', height: '100%', objectFit: 'cover'}}/>
                            <div className="overlay">
                                <h4>{caseStudy.title}</h4>
                            </div>
                        </NavLink>
                    </div>
                </div>
            )
        });
        return body;
    }

    productsBody = () => {
        let body = [],
            products = [
                {
                    name: 'Full Stack Development',
                    description: 'From frontend to the backend and databases, our engineers ensure the security, scalability and performance of your application.',
                    image: 'full-stack-development'
                },
                {
                    name: 'Mobile Development',
                    description: 'Cross-functional and Native app development with UX-driven approach and responsive prototyping, we ensure that what you see is what you get.',
                    image: 'mobile-development'
                },
                {
                    name: 'Cloud & DevOps',
                    description: 'Design & develop or migrate existing on-premises applications to the cloud with fully automated CI/CD pipelines to elevate your business to the next level.',
                    image: 'cloud'
                },
                {
                    name: 'Product Concept & Design',
                    description: 'Optimizing the path from issue to solution, we generate effective approaches aimed at maximizing user satisfaction as well as fulfilling client requirements.',
                    image: 'product-concept'
                },
                {
                    name: 'Quality Assurance',
                    description: 'Keeping in line with the highest standards through continual manual and automated testing, we strive to minimize inefficiencies while eliminating potential bugs and delays.',
                    image: 'quality-assurance'
                },
                {
                    name: 'Product Management',
                    description: 'Focusing on business and technology, our product managers develop both the vision and the strategy while providing leadership to guide you toward a successful product.',
                    image: 'product-management'
                },
            ];
        products.forEach((product, index) => {
            body.push(
                <div className="col product" key={index}>
                    <img src={require(`../assets/images/${product.image}.png`)} alt={product.name}
                         className="product-image"/>
                    <h3 className="product-name">{product.name}</h3>
                    <p className="product-description">{product.description}</p>
                </div>
            )
        });
        return body;
    }

    render() {
        let {testimonials, caseStudies, loading} = this.state;
        return (
            <>
                {loading ? <Loading/> : null}
                <div style={{display: loading ? 'none' : 'block'}}>
                    <div className="container home-header">
                        <div className="row">
                            <div className="col">
                                <h1 className='home-header-heading'>
                                    Taking on the <br/> world with evolving<br/> technologies.
                                </h1>
                                <p className='home-header-description'>
                                    Excelling in the world ahead with highly skilled software engineers in Norway
                                    and Sri Lanka.
                                </p>
                                <button className="btn blue-button">
                                    <NavLink className="nav-link" to="/case-studies">Our Work</NavLink>
                                </button>
                                <button className="btn pink-border-button">
                                    <a href="mailto: hello@excelly.io" className="nav-link">Contact Us</a>
                                </button>
                            </div>
                            <div className="col text-center" style={{position: 'relative'}}>
                                <div className="about-us-description" style={{right: '2%'}}>
                                    <img src={homeHeader} alt="Home Header" style={{height: '100%'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container client-container">
                        <hr/>
                        <Clients/>
                    </div>
                    <div className="product-container">
                        <div className="container text-center">
                            <h2 className="container-heading">What we offer</h2>
                            <div className="row row-cols-3 text-center" style={{padding: '2% 0'}}>
                                {this.productsBody()}
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-container">
                        <div className="container text-center" style={{padding: 0}}>
                            <h2 className="container-heading">Client Testimonials</h2>
                            <Slider dots={false} arrows={false} autoplay={true} infinite={true} pauseOnHover={true}
                                    slidesToShow={(isMobile || isTablet) ? 1 : 2}
                                    slidesToScroll={1}>
                                {this.testimonialBody(testimonials)}
                            </Slider>
                        </div>
                    </div>
                    <div className="about-us-container">
                        <div className="row" style={{width: '100%'}}>
                            <div className="col">
                                <img src={aboutUs} alt="About Us" style={{width: '75%'}}
                                     className="about-us-desktop-image"/>
                                <img src={aboutUsMobile} alt="About Us" className="about-us-mobile-image"/>
                            </div>
                            <div className="col" style={{position: 'relative'}}>
                                <div className="about-us-description">
                                    <h2 className="container-heading">About Excelly</h2>
                                    <p className="product-description" style={{width: '50%'}}>
                                        Excelly is a Norwegian-Sri Lankan tech company that works hand in hand with
                                        clients
                                        mainly from the Nordic region and now expanding to the rest of the
                                        world.<br/><br/>
                                        Our goal is to excel at what we do and provide our clientele and partners with
                                        knowledge, support, and teams that can help their businesses grow!
                                    </p>
                                    <button className="btn blue-button" style={{marginTop: '5%'}}>
                                        <NavLink className="nav-link" to="/about-us">More about us</NavLink>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="case-study-container">
                        <div className="container">
                            <div className="row">
                                <div className="col" style={{padding: 0}}>
                                    <div>
                                        <h2 className="container-heading">Case Studies<br/> from our work.</h2>
                                        <p className="product-description" style={{width: '75%'}}>
                                            Our work, simplified. Understand how Excelly brings your product to life
                                            through innovative technologies.
                                        </p>
                                        <button className="btn blue-button" style={{marginTop: '5%'}}>
                                            <NavLink className="nav-link" to="/case-studies">View Work</NavLink>
                                        </button>
                                    </div>
                                </div>
                                <div className="col" style={{position: 'relative'}}>
                                    <div className="row row-cols-2 about-us-description case-study-row">
                                        {this.caseStudyBody(caseStudies)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about-us-container" style={{backgroundColor: 'white'}}>
                        <div className="row" style={{width: '100%'}}>
                            <div className="col">
                                <img src={connectWithUs} alt="Connect With Us"/>
                            </div>
                            <div className="col" style={{position: 'relative'}}>
                                <div className="about-us-description">
                                    <h2 className="container-heading">Connect with us today!</h2>
                                    <p className="product-description">
                                        Send us your requirement, let’s excel together.
                                    </p>
                                    <button className="btn blue-button" style={{marginTop: '5%'}}>
                                        <a href="mailto: hello@excelly.io" className="nav-link">Get in touch</a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Home;
