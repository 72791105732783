import React, {Component} from 'react';

import Testimonial from "../components/portal/Testimonial";
import CaseStudy from "../components/portal/CaseStudy";
import Blog from "../components/portal/Blog";
import BlogAuthor from "../components/portal/BlogAuthor";
import ClientLogo from "../components/portal/ClientLogo";

import arrow from '../assets/images/arrow.svg';

import '../assets/css/Portal.css';

class Portal extends Component {
    render() {
        return (
            <div className="portal-container">
                <div className="portal-container-navbar">
                    <div className="vertical-nav">
                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                             aria-orientation="vertical">
                            <a className="nav-link active" id="v-pills-client-logos-tab" data-toggle="pill"
                               href="#v-pills-client-logos" role="tab" aria-controls="v-pills-client-logos"
                               aria-selected="true">
                                <img src={arrow} alt="Arrow" className="link-arrow"/>
                                Partners
                            </a>
                            <a className="nav-link" id="v-pills-testimonials-tab" data-toggle="pill"
                               href="#v-pills-testimonials" role="tab" aria-controls="v-pills-testimonials"
                               aria-selected="false">
                                <img src={arrow} alt="Arrow" className="link-arrow"/>
                                Testimonials
                            </a>
                            <a className="nav-link" id="v-pills-case-studies-tab" data-toggle="pill"
                               href="#v-pills-case-studies" role="tab" aria-controls="v-pills-case-studies"
                               aria-selected="false">
                                <img src={arrow} alt="Arrow" className="link-arrow"/>
                                Case Studies
                            </a>
                            <a className="nav-link" id="v-pills-blogs-tab" data-toggle="pill"
                               href="#v-pills-blogs" role="tab" aria-controls="v-pills-blogs"
                               aria-selected="false">
                                <img src={arrow} alt="Arrow" className="link-arrow"/>
                                Blog Articles
                            </a>
                            <a className="nav-link" id="v-pills-blog-authors-tab" data-toggle="pill"
                               href="#v-pills-blog-authors" role="tab" aria-controls="v-pills-blog-authors"
                               aria-selected="false">
                                <img src={arrow} alt="Arrow" className="link-arrow"/>
                                Blog Authors
                            </a>
                        </div>
                    </div>
                    <div className="nav-detail-container">
                        <div className="tab-content portal-tab-container" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-client-logos" role="tabpanel"
                                 aria-labelledby="v-pills-client-logos-tab">
                                <ClientLogo/>
                            </div>
                            <div className="tab-pane fade" id="v-pills-testimonials" role="tabpanel"
                                 aria-labelledby="v-pills-testimonials-tab">
                                <Testimonial/>
                            </div>
                            <div className="tab-pane fade" id="v-pills-case-studies" role="tabpanel"
                                 aria-labelledby="v-pills-case-studies-tab">
                                <CaseStudy/>
                            </div>
                            <div className="tab-pane fade" id="v-pills-blogs" role="tabpanel"
                                 aria-labelledby="v-pills-blogs-tab">
                                <Blog/>
                            </div>
                            <div className="tab-pane fade" id="v-pills-blog-authors" role="tabpanel"
                                 aria-labelledby="v-pills-blog-authors-tab">
                                <BlogAuthor/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default Portal;
