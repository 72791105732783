import React, {Component} from 'react';
import {Helmet} from "react-helmet";

import Loading from "../components/Loading";

import careersHeader from '../assets/images/careers-header.jpg';
import careers from '../assets/images/careers.jpg';

import '../assets/css/Home.css';
import '../assets/css/AboutUs.css';
import '../assets/css/Rooster.css';

class Careers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({loading: false});
        }, 2500);
    }

    render() {
        let {loading} = this.state;
        return (
            <>
                {loading ? <Loading/> : null}
                <div style={{display: loading ? 'none' : 'block'}}>
                    <div className="container home-header about-us-header text-center">
                        <h1 className='home-header-heading'>
                            Working at Excelly
                        </h1>
                        <p className='home-header-description about-us-header-description'>
                            Friendly and casual yet highly focused and goal oriented, Excelly is driven by the
                            passion to bring out the best in every member of every team whether in Norway or Sri Lanka.
                        </p>
                        <img src={careersHeader} alt="Careers Header" className="about-us-header-image"/>
                    </div>
                    <div className="founders-container">
                        <div className="container">
                            <div className="row" style={{width: '100%', margin: 0}}>
                                <div className="col" style={{position: 'relative', padding: 0}}>
                                    <div className="about-us-description">
                                        <h2 className="container-heading" style={{marginTop: 0}}>Our Careers</h2>
                                        <p className="product-description" style={{width: '80%'}}>
                                            Maintaining a highly conducive environment for teams across two continents,
                                            Excelly nurtures a unique company culture that places quality over quantity.
                                            Our
                                            positive guidance encourages all members of every team to strive for
                                            excellence.
                                        </p>
                                    </div>
                                </div>
                                <div className="col" style={{marginLeft: '11%', padding: 0}}>
                                    <img src={careers} alt="Careers" style={{borderRadius: '32px'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="founders-container" id="rooster-container"
                         style={{backgroundColor: 'white', display: 'none'}}>
                        <div className="container">
                            <h2 className="container-heading">Explore Opportunities</h2>
                            <div id="rooster"/>
                        </div>
                    </div>
                </div>
                <Helmet>
                    <script src="https://roostercdn.s3-ap-southeast-1.amazonaws.com/rooster.min.js"
                            type="text/javascript"/>
                </Helmet>
                {!loading ?
                    <Helmet>
                        <script defer={true}>{`
                            var rooster;
                            if (true) rooster = new Rooster('#rooster', 12940);
                            rooster.setup();
                            var roosterElement = document.getElementsByClassName('jobs-list')[0];
                            var roosterContainer = document.getElementById('rooster-container');
                            setTimeout(function(){ if (roosterElement.hasChildNodes()) roosterContainer.style.display = 'block' }, 500);
                        `}</script>
                    </Helmet>
                    : null
                }
            </>
        )
    }
}

export default Careers;
