import React, {Component} from 'react';

import Clients from "../components/Clients";
import Loading from "../components/Loading";

import {loadCaseStudies} from "../common/Common";

import '../assets/css/Home.css';
import '../assets/css/CaseStudies.css';

class CaseStudies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            caseStudies: [],
            loading: true,
        };
    }

    componentDidMount() {
        loadCaseStudies().then(data => {
            this.setState({caseStudies: data});
        }).catch(() => null);
        setTimeout(() => {
            this.setState({loading: false});
        }, 2500);
    }

    caseStudyBody = (caseStudies) => {
        let body = [];
        caseStudies.forEach((caseStudy, index) => {
            let technologyStack = [],
                id = caseStudy.title.replace(/\s+/g, '-');
            caseStudy.technology_list.split(",").forEach((technology, index) => {
                if (index === caseStudy.technology_list.length - 1) {
                    technologyStack.push(
                        <span key={index} className="technology">{technology}</span>
                    )
                } else {
                    technologyStack.push(
                        <span key={index}>
                            <span className="technology">{technology}</span>&emsp;
                        </span>
                    )
                }
            });
            body.push(
                <div className="row case-study" key={index} id={id}>
                    <div className="col-md-auto">
                        <img src={caseStudy.image} alt={caseStudy.title}
                             style={{borderRadius: '40px', width: '484px', height: '340px'}}/>
                    </div>
                    <div className="col" style={{position: 'relative', marginLeft: '2%'}}>
                        <div className="about-us-description">
                            <h3 className="product-name" style={{margin: '2% 0'}}>{caseStudy.title}</h3>
                            <div style={{marginBottom: '1%', textAlign: 'left'}}>{technologyStack}</div>
                            <p className="product-description case-study-description">{caseStudy.description}</p>
                        </div>
                    </div>
                </div>
            )
        });
        return body;
    }

    scroll = (id) => {
        const observer = new MutationObserver((mutations, obs) => {
            let caseStudyDiv = document.getElementById(id);
            if (caseStudyDiv) {
                caseStudyDiv.scrollIntoView({behavior: 'smooth', block: 'center'});
                obs.disconnect();
            }
        });
        observer.observe(document, {childList: true, subtree: true});
    }

    render() {
        let {caseStudies, loading} = this.state,
            location = window.location.href;
        return (
            <>
                {loading ? <Loading/> : null}
                <div style={{display: loading ? 'none' : 'block'}}>
                    <div className="container home-header text-center">
                        <h1 className='home-header-heading'>
                            Case Studies
                        </h1>
                        <p className='home-header-description about-us-header-description'>
                            Driven by the passion for outstanding outcomes and enduring relationships, our work embodies
                            our
                            core values of hard work, trust and responsibility. Excelly represents a journey that
                            extends
                            far beyond its destination, for success is a bond not just a result.
                        </p>
                    </div>
                    <div className="container" style={{padding: 0}}>
                        {[
                            this.caseStudyBody(caseStudies),
                            this.scroll(location.split('#').at(-1))
                        ]}
                    </div>
                    <div className="container client-container">
                        <Clients/>
                    </div>
                </div>
            </>
        )
    }
}

export default CaseStudies;
