import React, {Component} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import {isMobile, isTablet} from 'react-device-detect';

import {loadClientLogos} from "../common/Common";

class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientLogos: [],
        };
    }

    componentDidMount() {
        loadClientLogos().then(data => {
            this.setState({clientLogos: data});
        }).catch(() => null);
    }

    clientBody = (clientLogos) => {
        let returnBody = [];
        clientLogos.forEach((clientLogo, index) => {
            returnBody.push(
                <div className="text-center client-row" key={index}>
                    <img src={clientLogo.profile_image} alt={clientLogo.name} style={{maxWidth: 150}}/>
                </div>
            );
        });
        return returnBody;
    }

    render() {
        let {clientLogos} = this.state;
        return (
            <Slider dots={false} arrows={false} autoplay={true} infinite={true} pauseOnHover={false}
                    slidesToShow={(isMobile || isTablet) ? 2 : 4}
                    slidesToScroll={1}>
                {this.clientBody(clientLogos)}
            </Slider>
        );
    }
}

export default Clients;
