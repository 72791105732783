import React, {Component} from 'react';

import Loading from "../components/Loading";

import connectWithUs from "../assets/images/connect-with-us-right.png";
import technologies from "../assets/images/technologies.png";

import '../assets/css/Home.css';
import '../assets/css/AboutUs.css';
import '../assets/css/Services.css';

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({loading: false});
        }, 2500);
    }

    render() {
        let {loading} = this.state;
        return (
            <>
                {loading ? <Loading/> : null}
                <div style={{display: loading ? 'none' : 'block'}}>
                    <div className="container home-header text-center">
                        <h1 className='home-header-heading'>
                            Start your journey with Excelly
                        </h1>
                        <p className='home-header-description about-us-header-description'>
                            Anchored in two continents, Excelly propels you to excellence from the very beginning.
                            Unparalleled transparency merges with incomparable efficiency at every step of the
                            development process. Working hand in hand with each client, we progress toward the
                            optimal solution that adheres to the highest industry standards and maintains the highest
                            respect for cultural sensitivities.
                        </p>
                    </div>
                    <div className="founders-container services-container">
                        <div className="container" style={{
                            paddingRight: `calc(var(--bs-gutter-x) * .5)`,
                            paddingLeft: `calc(var(--bs-gutter-x) * .5)`
                        }}>
                            <div className="row">
                                <div className="col">
                                    <h2 className="container-heading"
                                        style={{margin: 0, marginBottom: '5%'}}>Partnerships</h2>
                                    <p className="product-description" style={{color: 'white'}}>
                                        Long-term endeavours embodying the highest standards throughout, our
                                        partnerships pave the way for enduring professional bonds.<br/><br/>
                                        Focused on timely execution and mutual trust, every partnership signifies
                                        collaboration at its finest.
                                    </p>
                                </div>
                                <div className="col">
                                    <h2 className="container-heading" style={{margin: 0, marginBottom: '5%'}}>Extended
                                        Teams</h2>
                                    <p className="product-description" style={{color: 'white'}}>
                                        Developing your product idea from initial concept to final delivery, Excelly
                                        assembles the best team for you to accelerate your journey to success.<br/><br/>
                                        We also facilitate team expansion by bringing together the top talent to
                                        supplement your existing team and further strengthen your endeavour.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="case-study-container technology-container" style={{minHeight: 'auto'}}>
                        <div className="container">
                            <div className="row" style={{width: '100%'}}>
                                <div className="col">
                                    <img src={technologies} alt="Technologies"/>
                                </div>
                                <div className="col" style={{position: 'relative'}}>
                                    <div className="about-us-description">
                                        <h2 className="container-heading">Gearing up with<br/> latest technologies.</h2>
                                        <p className="product-description services-description">
                                            Powered by the newest tools & technologies for unmatched quality and
                                            optimized
                                            performance, our solutions are designed to maximize your productivity.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about-us-container" style={{backgroundColor: 'white'}}>
                        <div className="row" style={{width: '100%', margin: 0}}>
                            <div className="col" style={{position: 'relative', marginLeft: '15%', padding: 0}}>
                                <div className="about-us-description">
                                    <h2 className="container-heading">Connect with us today!</h2>
                                    <p className="product-description">
                                        Send us your requirement, let’s excel together.
                                    </p>
                                    <button className="btn blue-button" style={{marginTop: '5%'}}>
                                        <a href="mailto: hello@excelly.io" className="nav-link">Get in touch</a>
                                    </button>
                                </div>
                            </div>
                            <div className="col" style={{textAlign: 'right', padding: 0}}>
                                <img src={connectWithUs} alt="Connect With Us"/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Services;
