import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

import Loading from "../components/Loading";
import Pagination from "../components/pagination/Pagination";

import {loadBlogs} from "../common/Common";

import '../assets/css/Home.css';
import '../assets/css/CaseStudies.css';
import '../assets/css/Blog.css';

class Blogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogs: [],
            loading: true,
            currentPage: 1,
        };
    }

    componentDidMount() {
        loadBlogs().then(data => {
            this.setState({blogs: data});
        }).catch(() => null);
        setTimeout(() => {
            this.setState({loading: false});
        }, 2500);
    }

    blogBody = (blogs) => {
        let body = [],
            count = 0;
        blogs.forEach((blog, index) => {
            // let tags = [];
            count = count < 3 ? count + 1 : 1;
            // blog.tags.split(",").forEach((tag, index) => {
            //     if (index === blog.tags.length - 1) {
            //         tags.push(
            //             <span key={index}>{tag}</span>
            //         )
            //     } else {
            //         tags.push(
            //             <span key={index}>{tag} | </span>
            //         )
            //     }
            // });
            body.push(
                <div
                    className={"col text-center" + (count % 3 === 0 ? ' third' : count % 2 === 0 ? ' second' : '')}
                    key={index}
                    style={{marginBottom: '5%', padding: 0}}>
                    <div>
                        {blog.is_link ?
                            <a href={blog.link} target="_blank" style={{textDecoration: 'none'}}>
                                <img src={blog.main_image} alt={blog.title}
                                     style={{borderRadius: '32px', width: '100%'}}/>
                                <h3 className="product-name"
                                    style={{color: 'var(--primary-font-color)'}}>{blog.title}</h3>
                                {/*<div style={{marginBottom: '2%', color: '#A2A5B2'}}>{tags}</div>*/}
                                <p className="product-description">by <span
                                    style={{textTransform: 'capitalize'}}>{blog.author_name && blog.author_name.toLowerCase() !== 'null' ? blog.author_name : blog.author.name}</span>
                                </p>
                            </a>
                            : <NavLink to={"/blog/content/" + blog.slug} style={{textDecoration: 'none'}}>
                                <img src={blog.main_image} alt={blog.title}
                                     style={{borderRadius: '32px', width: '100%'}}/>
                                <h3 className="product-name"
                                    style={{color: 'var(--primary-font-color)'}}>{blog.title}</h3>
                                {/*<div style={{marginBottom: '2%', color: '#A2A5B2'}}>{tags}</div>*/}
                                <p className="product-description">by <span
                                    style={{textTransform: 'capitalize'}}>{blog.author_name && blog.author_name.toLowerCase() !== 'null' ? blog.author_name : blog.author.name}</span>
                                </p>
                            </NavLink>
                        }
                    </div>
                </div>
            )
        });
        return body;
    }

    render() {
        let {blogs, loading, currentPage} = this.state,
            pageSize = 6;
        const firstPageIndex = (currentPage - 1) * pageSize,
            lastPageIndex = firstPageIndex + pageSize,
            currentPageBlogs = blogs.slice(firstPageIndex, lastPageIndex);
        return (
            <>
                {loading ? <Loading/> : null}
                <div style={{display: loading ? 'none' : 'block'}}>
                    <div className="container home-header text-center">
                        <h1 className='home-header-heading'>
                            Blog Articles
                        </h1>
                        <p className='home-header-description about-us-header-description'>
                            Stay updated with Excelly for the latest news on technology<br/>
                            plus our team, our partners, and our clientele.
                        </p>
                    </div>
                    <div className="container blog-container">
                        <div className="row row-cols-3">
                            {this.blogBody(currentPageBlogs)}
                        </div>
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={blogs.length}
                            pageSize={pageSize}
                            onPageChange={page => this.setState({currentPage: page})}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default Blogs;
