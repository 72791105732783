import React, {Component} from 'react';
import EditorJS from "@editorjs/editorjs";
import {getEditorJSTools} from "../components/portal/editor-constants";
import moment from 'moment';

import Loading from "../components/Loading";

import {loadBlog} from "../common/Common";

import '../assets/css/Home.css';
import '../assets/css/AboutUs.css';
import '../assets/css/Blog.css';

let editor = null;

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            blog: null,
        };
    }

    componentDidMount() {
        editor = null;
        const slug = window.location.href.split('/').at(-1);
        loadBlog(slug).then(data => {
            this.setState({blog: data});
        }).catch(() => null);
        setTimeout(() => {
            this.setState({loading: false});
        }, 2500);
    }

    render() {
        let {loading, blog} = this.state;
        if (blog && !editor) {
            editor = new EditorJS({
                holder: 'editor',
                tools: getEditorJSTools(null),
                readOnly: true,
                data: JSON.parse(blog.content),
            });
        }
        return (
            <>
                {loading ? <Loading/> : null}
                {blog ?
                    <div style={{display: loading ? 'none' : 'block'}}>
                        <div className="container home-header about-us-header blog-author-container">
                            <div className="row" style={{marginBottom: '3%'}}>
                                <div className="col-1">
                                    <img src={blog.author.profile_image}
                                         alt={blog.author.name}
                                         style={{borderRadius: 32, width: 65, height: 65}}/>
                                </div>
                                <div className="col-11" style={{position: 'relative'}}>
                                    <div className="about-us-description">
                                    <span style={{
                                        textTransform: 'capitalize',
                                        fontWeight: 'bold'
                                    }}>{blog.author.name}</span><br/>
                                        <span style={{color: 'var(--secondary-font-color)'}}>
                                            {moment(blog.published_at).utc().format('MMMM D, YYYY')}
                                            {/*<span style={{fontSize: 15}}>&emsp;&#x25CF;&emsp;</span> {blog.duration} min read*/}
                                    </span>
                                    </div>
                                </div>
                            </div>
                            <h1 className='home-header-heading' style={{fontSize: 40}}>
                                {blog.title}
                            </h1>
                        </div>
                        <div className="container" style={{padding: 0}}>
                            <div style={{margin: '2% 0'}}>
                                <div id="editor"/>
                            </div>
                        </div>
                    </div>
                    : null
                }
            </>
        )
    }
}

export default Blog;
