export const getRequest = async (endpoint, token) => {
    const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
            'x-access-token': token ? token : null
        },
    });
    return {data: JSON.parse(await response.text()), status: response.status};
};

export const postRequest = async (values) => {
    const response = await fetch(values.endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': values.token ? values.token : null
        },
        body: JSON.stringify(values),
    });
    return {data: JSON.parse(await response.text()), status: response.status};
};

export const deleteRequest = async (endpoint, token) => {
    const response = await fetch(endpoint, {
        method: 'DELETE',
        headers: {
            'x-access-token': token ? token : null
        },
    });
    return {data: JSON.parse(await response.text()), status: response.status};
};

export const putRequest = async (values) => {
    const response = await fetch(values.endpoint, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': values.token ? values.token : null
        },
        body: JSON.stringify(values),
    });
    return {data: JSON.parse(await response.text()), status: response.status};
};

export const uploadFile = async (data, endpoint, token, put) => {
    const response = await fetch(endpoint, {
        method: put ? 'PUT' : 'POST',
        body: data,
        headers: {
            'x-access-token': token ? token : null
        },
    });
    return {data: JSON.parse(await response.text()), status: response.status};
};
