import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

import logo from '../assets/images/logo.png';
import linkedIn from '../assets/images/linkedIn.svg';
import facebook from '../assets/images/facebook.svg';
import instagram from '../assets/images/instagram.svg';
import location from '../assets/images/location.png';

import '../assets/css/Footer.css';

class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col text-center mobile-image">
                            <img src={logo} alt="Logo" className="logo"/>
                        </div>
                        <div className="col">
                            <ul className="navbar-nav d-flex">
                                <li className="nav-item" style={{width: '30%'}}>
                                    <NavLink className="nav-link" to="/about-us">About</NavLink>
                                </li>
                                <li className="nav-item" style={{width: '30%'}}>
                                    <NavLink className="nav-link" to="/privacy-policy">Privacy Policy</NavLink>
                                </li>
                            </ul>
                            <div className="row" style={{marginTop: '10%'}}>
                                <div className="col">
                                    <ul className="navbar-nav d-flex">
                                        {/*<li className="nav-item">*/}
                                        {/*    <a className="nav-link" href="https://twitter.com/"*/}
                                        {/*       target="_blank">*/}
                                        {/*        <img src={twitter} alt="Twitter"/>*/}
                                        {/*    </a>*/}
                                        {/*</li>*/}
                                        <li className="nav-item">
                                            <a className="nav-link" href="https://www.linkedin.com/company/excelly/"
                                               target="_blank">
                                                <img src={linkedIn} alt="LinkedIn"/>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="https://www.facebook.com/excelly.io/"
                                               target="_blank">
                                                <img src={facebook} alt="Facebook"/>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="https://www.instagram.com/excelly.io/"
                                               target="_blank">
                                                <img src={instagram} alt="Instagram"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col desktop-image"/>
                            </div>
                        </div>
                        <div className="col text-center desktop-image">
                            <img src={logo} alt="Logo" className="logo"/>
                        </div>
                        <div className="col address-container">
                            <div className="d-flex">
                                <div className="p-2"><img src={location} alt="location"/></div>
                                <div className="p-2" style={{color: "var(--secondary-font-color)"}}>
                                    <span style={{color: "var(--primary-color)", fontWeight: 700}}>Norway</span><br/>
                                    Smedstusvingen 18<br/>
                                    1480 Slattum
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="p-2"><img src={location} alt="location"/></div>
                                <div className="p-2" style={{color: "var(--secondary-font-color)"}}>
                                    <span style={{color: "var(--primary-color)", fontWeight: 700}}>Sri Lanka</span><br/>
                                    440B, Batagama South, Kandana
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="rights text-center">
                        &#169; {new Date().getFullYear()} Excelly, All rights reserved.
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
