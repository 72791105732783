import React, {Component} from 'react';
import {toast} from 'react-toastify';
import EditorJS from '@editorjs/editorjs';
import {getEditorJSTools} from "./editor-constants";
import CreatableSelect from "react-select/creatable";

import {deleteRequest, uploadFile} from "../../routes/Routes";
import {getUser} from "../../auth/Auth";
import {handleDeleteAllCheckBox, handleDeleteCheckBox, loadAdminBlogAuthors, loadAdminBlogs} from "./Common";

import addImage from '../../assets/images/add.png';

let editor = null;

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            action: "",
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
            blogs: [],
            deleteBlogs: [],
            selectedBlog: null,
            image: null,
            blogAuthors: [],
            isLink: true,
        };
        this.imageInput = React.createRef();
    }

    componentDidMount() {
        getUser(true).then(data => {
            this.setState({user: data});
            loadAdminBlogs(data.token).then(data => {
                this.setState({blogs: data});
            }).catch(() => null);
            loadAdminBlogAuthors(data.token).then(data => {
                this.setState({blogAuthors: data});
            }).catch(() => null);
        }).catch(() => null);
    }

    render() {
        let {
            loading,
            action,
            error,
            errorMessage,
            success,
            successMessage,
            blogs,
            deleteBlogs,
            selectedBlog,
            image,
            blogAuthors,
            user,
            isLink,
        } = this.state;
        if (user && (action === 'Add New Blog' || action === 'Edit Blog') && !editor && ((selectedBlog && !selectedBlog.is_link) || !isLink)) {
            editor = new EditorJS({
                holder: 'editor',
                tools: getEditorJSTools(user),
                placeholder: 'Blog content',
                data: selectedBlog ? JSON.parse(selectedBlog.content) : null,
            });
        }
        return (
            <div>
                {action === 'Add New Blog' || action === 'Edit Blog' ?
                    <form onSubmit={this.formSubmit} className="portal-form" style={{width: '80%'}}>
                        <h1 className="form-heading" key="heading"
                            style={{
                                marginBottom: '1%',
                                color: 'var(--primary-font-color)',
                                fontWeight: 'bold'
                            }}>{action}</h1>
                        <p className="product-description" style={{fontWeight: 'normal'}}>
                            Add / Remove / Edit Blogs.
                        </p>
                        <div className="row" style={{width: '100%'}}>
                            <div className="col">
                                <div className="row" style={{width: '100%'}}>
                                    <div className="form-group col">
                                        <label htmlFor="title" className="form-label">Title</label>
                                        {selectedBlog ?
                                            <input className="form-control" type="text" placeholder='Title' id="title"
                                                   required
                                                   value={selectedBlog.title}
                                                   onChange={(event) => this.handleChange(event, 'title')}/>
                                            : <input className="form-control" type="text" placeholder='Title' id="title"
                                                     required/>
                                        }
                                    </div>
                                    {/*<div className="form-group col">*/}
                                    {/*    <label htmlFor="tags" className="form-label">Tags</label>*/}
                                    {/*    {selectedBlog ?*/}
                                    {/*        <input className="form-control" type="text" placeholder='Tags'*/}
                                    {/*               id="tags" required*/}
                                    {/*               value={selectedBlog.tags}*/}
                                    {/*               onChange={(event) => this.handleChange(event, 'tags')}/>*/}
                                    {/*        : <input className="form-control" type="text" placeholder='Tags'*/}
                                    {/*                 id="tags" required/>*/}
                                    {/*    }*/}
                                    {/*    <small id="tags" className="form-text text-muted">*/}
                                    {/*        Separate using comma "," ie: .NET, Microsoft Azure*/}
                                    {/*    </small>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="row" style={{width: '100%'}}>
                                    <div className="form-group col">
                                        <label htmlFor="author" className="form-label">Author</label>
                                        <CreatableSelect className="basic-single btn w-100 select"
                                                         placeholder="Author"
                                                         name="author" id="author" options={blogAuthors}
                                                         defaultValue={selectedBlog ? typeof selectedBlog.author === "object" ? selectedBlog.author : blogAuthors.find((blogAuthor) => {
                                                             return blogAuthor.id === Number(selectedBlog.author_id);
                                                         }) : null}
                                                         getOptionValue={option => option['id']}
                                                         getOptionLabel={option => option['name']} required
                                                         getNewOptionData={(inputValue, optionLabel) => ({
                                                             id: optionLabel,
                                                             name: inputValue,
                                                             __isNew__: true
                                                         })}
                                                         onChange={(event) => this.handleChange(event, 'author')}/>
                                    </div>
                                    {/*<div className="form-group col">*/}
                                    {/*    <label htmlFor="duration" className="form-label">Read Duration</label>*/}
                                    {/*    {selectedBlog ?*/}
                                    {/*        <input className="form-control" type="number" placeholder='Duration'*/}
                                    {/*               id="duration" required*/}
                                    {/*               value={selectedBlog.duration}*/}
                                    {/*               onChange={(event) => this.handleChange(event, 'duration')}/>*/}
                                    {/*        : <input className="form-control" type="number" placeholder='Duration'*/}
                                    {/*                 id="duration"*/}
                                    {/*                 required/>*/}
                                    {/*    }*/}
                                    {/*    <small id="duration" className="form-text text-muted">*/}
                                    {/*        Reading time duration in minutes*/}
                                    {/*    </small>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="col">
                                {image ?
                                    <img src={URL.createObjectURL(image)} style={{marginBottom: '5%'}}/>
                                    : selectedBlog ?
                                        <img src={selectedBlog.main_image} style={{marginBottom: '5%'}}/>
                                        : null
                                }
                                <div className="custom-file" style={{height: '30vh'}}>
                                    <input type="file" className="custom-file-input" id="blogImage" accept="image/*"
                                           ref={this.imageInput}
                                           onChange={(event) => this.handleChange(this.imageInput, 'image')}/>
                                    <label className="custom-file-label" htmlFor="blogImage">
                                        <img src={addImage} alt="Add Icon"/>
                                        <div className="image-uploader-label">
                                            Drag & drop or select from your files
                                        </div>
                                    </label>
                                    <small id="image" className="form-text text-muted">
                                        Add Blog Image
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="form-group radio-button-container">
                            <div className="custom-control custom-radio form-check-inline">
                                <input type="radio" id="isLink" name="is_link" value={true}
                                       className="custom-control-input"
                                       defaultChecked={selectedBlog ? selectedBlog.is_link : isLink}
                                       onChange={() => this.handleChange(true, 'is_link')}/>
                                <label className="custom-control-label" htmlFor="isLink">Link</label>
                            </div>
                            <div className="custom-control custom-radio form-check-inline">
                                <input type="radio" id="blogEditor" name="is_link" value={false}
                                       className="custom-control-input"
                                       defaultChecked={selectedBlog ? !selectedBlog.is_link : !isLink}
                                       onChange={() => this.handleChange(false, 'is_link')}/>
                                <label className="custom-control-label" htmlFor="blogEditor">Blog Editor</label>
                            </div>
                        </div>
                        {(selectedBlog && selectedBlog.is_link) || isLink ?
                            <div className="form-group" style={{width: '25%'}}>
                                <label htmlFor="link" className="form-label">Redirect Link</label>
                                {selectedBlog ?
                                    <input className="form-control" type="text" placeholder='Redirect Link' id="link"
                                           required
                                           value={selectedBlog.link}
                                           onChange={(event) => this.handleChange(event, 'link')}/>
                                    : <input className="form-control" type="text" placeholder='Redirect Link' id="link"
                                             required/>
                                }
                            </div>
                            : [
                                <label htmlFor="editor" className="form-label" key="blogLabel">Blog Editor</label>,
                                <div className="form-group text-editor" key="editor">
                                    <div id="editor"/>
                                </div>
                            ]
                        }
                        {success &&
                        <div className="alert alert-success" role="alert">
                            {successMessage}
                        </div>}
                        {error &&
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>}
                        <button className="btn pink-button form-button"
                                style={{marginTop: '5%', marginRight: '2%', width: '25%'}}>
                            {loading ?
                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                      aria-hidden="true"/>
                                : null
                            }
                            {action === 'Add New Blog' ? 'Add' : 'Save'}
                        </button>
                        <button className="btn white-button form-button" id=""
                                style={{marginTop: '5%', marginLeft: '2%', width: '25%'}}
                                onClick={this.actionClick}>
                            Cancel
                        </button>
                    </form>
                    : [
                        <h1 className="form-heading" key="heading"
                            style={{
                                marginBottom: '1%',
                                color: 'var(--primary-font-color)',
                                fontWeight: 'bold'
                            }}>Blogs</h1>,
                        <p className="product-description" key="paragraph" style={{fontWeight: 'normal'}}>
                            Add / Remove / Edit Blogs.
                        </p>,
                        <div key="blog-delete" className="action-container">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={'blogSelectAll'}
                                       checked={blogs.length === deleteBlogs.length}
                                       onChange={() => this.handleDeleteAllCheckBox()}/>
                                <label className="custom-control-label" htmlFor={'blogSelectAll'}>Select
                                    All</label>
                            </div>
                            <div className="action-button-container">
                                <button className="btn pink-border-button" onClick={this.delete}
                                        disabled={deleteBlogs.length === 0}>
                                    Delete
                                </button>
                                <button className="btn pink-button" id="Add New Blog" onClick={this.actionClick}>
                                    Add
                                </button>
                            </div>
                        </div>,
                        <table className="w-100 portal-table" key="blog-table">
                            <thead>
                            <tr>
                                <th/>
                                <th>No</th>
                                <th>Title</th>
                                {/*<th>Tags</th>*/}
                                <th>Author</th>
                                {/*<th>Duration</th>*/}
                                <th>Image</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {this.tableBody()}
                            </tbody>
                        </table>,
                    ]}
            </div>
        );
    };

    actionClick = (event, blog = null) => {
        if (!blog) event.preventDefault();
        editor = null;
        this.setState({
            action: blog ? event : event.target.id,
            selectedBlog: blog,
            isLink: blog ? blog.is_link : true,
            image: null,
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null
        });
    };

    formSubmit = async (event) => {
        let {action, user, blogs, selectedBlog, isLink} = this.state,
            endpoint,
            content = null;
        this.setState({loading: true});
        event.preventDefault();
        if (editor) {
            await editor.save().then((outputData) => {
                content = outputData;
            }).catch((error) => {
                console.log('Saving failed: ', error)
            });
        }
        const data = new FormData();
        if (action === 'Add New Blog') {
            if (event.target.author.value === '') {
                this.setState({
                    error: true,
                    errorMessage: "Please select an Author!",
                    success: false,
                    successMessage: null,
                });
                return;
            }
            data.append('title', event.target.title.value);
            data.append('author_id', !isNaN(parseInt(event.target.author.value)) ? event.target.author.value : null);
            data.append('author_name', isNaN(parseInt(event.target.author.value)) ? event.target.author.value : null);
            // data.append('tags', event.target.tags.value);
            // data.append('duration', event.target.duration.value);
            data.append('tags', null);
            data.append('duration', '1');
            data.append('is_link', isLink);
            data.append('link', event.target.link.value);
            data.append('content', JSON.stringify(content));
            if (this.imageInput.current.files.length > 0) {
                data.append('image', this.imageInput.current.files[0]);
            }
            endpoint = '/admin/api/blog/post';
        } else {
            data.append('title', selectedBlog.title);
            data.append('author_id', typeof selectedBlog.author === "object" && selectedBlog.author.hasOwnProperty('id') ? selectedBlog.author.id : selectedBlog.author_id);
            data.append('author_name', typeof selectedBlog.author === "object" && !selectedBlog.author.hasOwnProperty('id') ? selectedBlog.author.name : selectedBlog.author_name);
            data.append('tags', selectedBlog.tags);
            data.append('duration', selectedBlog.duration);
            data.append('is_link', selectedBlog.is_link);
            data.append('link', selectedBlog.link);
            data.append('content', JSON.stringify(content));
            if (selectedBlog.image) {
                data.append('image', selectedBlog.image);
            }
            endpoint = '/admin/api/blog/post/' + selectedBlog.id;
        }
        uploadFile(data, endpoint, user.token, action === 'Edit Blog').then(response => {
            if (response.status === 200) {
                if (action === 'Add New Blog') {
                    blogs.push(response.data.message.data);
                } else {
                    blogs[blogs.indexOf(selectedBlog)] = response.data.message.data;
                }
                this.setState({
                    error: false,
                    errorMessage: null,
                    success: true,
                    successMessage: action === 'Add New Blog' ? 'Successfully created Blog' : 'Successfully updated Blog',
                    blogs: blogs,
                    action: "",
                });
            } else {
                this.setState({
                    error: true,
                    errorMessage: response.data.message,
                    success: false,
                    successMessage: null,
                });
            }
            this.setState({loading: false});
        })
    }

    tableBody = () => {
        let body = [],
            {blogs, deleteBlogs, blogAuthors} = this.state;
        if (blogs) {
            blogs.forEach((blog, index) => {
                body.push(
                    <tr key={index}>
                        <td>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input"
                                       id={'blogDelete' + blog.id}
                                       checked={deleteBlogs.includes(blog.id)}
                                       onChange={(event) => this.handleDeleteCheckBox(blog.id)}/>
                                <label className="custom-control-label" htmlFor={'blogDelete' + blog.id}/>
                            </div>
                        </td>
                        <td>{index + 1}</td>
                        <td>
                            {blog.title}
                        </td>
                        {/*<td>*/}
                        {/*    {parse(blog.tags)}*/}
                        {/*</td>*/}
                        <td>
                            {blog.author_name && blog.author_name.toLowerCase() !== 'null' ? blog.author_name : typeof blog.author === "object" ? blog.author.name : blogAuthors.find((blogAuthor) => {
                                return blogAuthor.id === Number(blog.author_id);
                            }).name}
                        </td>
                        {/*<td>*/}
                        {/*    {blog.duration}*/}
                        {/*</td>*/}
                        <td className="image-column">
                            {blog.main_image ?
                                <img src={blog.main_image} alt={blog.title}
                                     style={{width: '65px', height: '65px'}}/>
                                : null
                            }
                        </td>
                        <td className="action-column">
                            <button onClick={() => this.actionClick('Edit Blog', blog)}>Edit</button>
                        </td>
                    </tr>
                )
            });
        }
        return body;
    }

    handleDeleteCheckBox = async (id) => {
        let {deleteBlogs} = this.state;
        this.setState({deleteBlogs: handleDeleteCheckBox(id, deleteBlogs)});
    };

    handleDeleteAllCheckBox() {
        let {deleteBlogs, blogs} = this.state;
        this.setState({deleteBlogs: handleDeleteAllCheckBox(deleteBlogs, blogs)});
    }

    delete = () => {
        let {user, blogs, deleteBlogs} = this.state;
        deleteBlogs.forEach((blog, index) => {
            deleteRequest('/admin/api/blog/post/' + blog, user.token).then(response => {
                if (response.status === 200) {
                    if (deleteBlogs.length === index + 1) {
                        this.setState({
                            blogs: blogs.filter(function (blog) {
                                return !deleteBlogs.includes(blog.id);
                            }),
                            deleteBlogs: []
                        });
                        toast.success('Successfully deleted Blogs', {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                } else {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
        });
    }

    handleChange(event, key) {
        let {selectedBlog, image} = this.state;
        if (selectedBlog) {
            if (key === 'image') {
                image = event.current.files[0];
                selectedBlog[key] = image;
            } else if (key === 'author') {
                event = event.__isNew__ ? {name: event.name} : event
                selectedBlog[key] = event;
            } else if (key === 'is_link') {
                selectedBlog[key] = event;
                editor = null;
                this.setState({isLink: event, selectedBlog: selectedBlog});
            } else {
                selectedBlog[key] = event.target.value;
            }
            this.setState({selectedBlog: selectedBlog, image: image});
        } else {
            if (key === 'is_link') {
                editor = null;
                this.setState({isLink: event});
            } else if (key !== 'author') {
                this.setState({image: event.current.files[0]});
            }
        }
    }
}

export default Blog;
