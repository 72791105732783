import React, {Component} from 'react';
import {toast} from 'react-toastify';
import parse from 'html-react-parser';

import {deleteRequest, uploadFile} from "../../routes/Routes";
import {getUser} from "../../auth/Auth";
import {handleDeleteAllCheckBox, handleDeleteCheckBox, loadAdminTestimonials} from "./Common";

import addImage from '../../assets/images/add.png';

class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            action: "",
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
            testimonials: [],
            deleteTestimonials: [],
            selectedTestimonial: null,
            image: null,
        };
        this.imageInput = React.createRef();
    }

    componentDidMount() {
        getUser(true).then(data => {
            this.setState({user: data});
            loadAdminTestimonials(data.token).then(data => {
                this.setState({testimonials: data});
            }).catch(() => null);
        }).catch(() => null);
    }

    render() {
        let {
            loading,
            action,
            error,
            errorMessage,
            success,
            successMessage,
            testimonials,
            deleteTestimonials,
            selectedTestimonial,
            image
        } = this.state;
        return (
            <div>
                {action === 'Add New Testimonial' || action === 'Edit Testimonial' ?
                    <form onSubmit={this.formSubmit} className="portal-form" style={{width: '80%'}}>
                        {window.scrollTo(0, 0)}
                        <h1 className="form-heading" key="heading"
                            style={{
                                marginBottom: '1%',
                                color: 'var(--primary-font-color)',
                                fontWeight: 'bold'
                            }}>{action}</h1>
                        <p className="product-description" style={{fontWeight: 'normal'}}>
                            Add / Remove / Edit Testimonials.
                        </p>
                        <div className="row" style={{width: '100%'}}>
                            <div className="form-group col">
                                <label htmlFor="name" className="form-label">Name</label>
                                {selectedTestimonial ?
                                    <input className="form-control" type="text" placeholder='Name' id="name" required
                                           value={selectedTestimonial.name}
                                           onChange={(event) => this.handleChange(event, 'name')}/>
                                    :
                                    <input className="form-control" type="text" placeholder='Name' id="name" required/>
                                }
                            </div>
                            <div className="form-group col">
                                <label htmlFor="designation" className="form-label">Designation</label>
                                {selectedTestimonial ?
                                    <input className="form-control" type="text" placeholder='Designation'
                                           id="designation"
                                           required
                                           value={parse(selectedTestimonial.designation)}
                                           onChange={(event) => this.handleChange(event, 'designation')}/>
                                    : <input className="form-control" type="text" placeholder='Designation'
                                             id="designation"
                                             required/>
                                }
                            </div>
                            <div className="form-group col">
                                <label htmlFor="company_name" className="form-label">Client</label>
                                {selectedTestimonial ?
                                    <input className="form-control" type="text" placeholder='Company Name'
                                           id="company_name"
                                           required
                                           value={selectedTestimonial.company_name}
                                           onChange={(event) => this.handleChange(event, 'company_name')}/>
                                    :
                                    <input className="form-control" type="text" placeholder='Company Name'
                                           id="company_name"
                                           required/>
                                }
                            </div>
                        </div>
                        <div className="row" style={{width: '100%'}}>
                            <div className="form-group col">
                                <label htmlFor="message" className="form-label">Testimonial / Message</label>
                                {selectedTestimonial ?
                                    <textarea className="form-control" id="message" placeholder="Message" required
                                              value={selectedTestimonial.message}
                                              onChange={(event) => this.handleChange(event, 'message')}/>
                                    : <textarea className="form-control" id="message" placeholder="Message" required/>
                                }
                            </div>
                            <div className="col">
                                {image ?
                                    <img src={URL.createObjectURL(image)} style={{marginBottom: '5%'}}/>
                                    : selectedTestimonial ?
                                        <img src={selectedTestimonial.profile_image} style={{marginBottom: '5%'}}/>
                                        : null
                                }
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="testimonialImage"
                                           accept="image/*"
                                           ref={this.imageInput}
                                           onChange={(event) => this.handleChange(this.imageInput, 'image')}/>
                                    <label className="custom-file-label" htmlFor="testimonialImage">
                                        <img src={addImage} alt="Add Icon"/>
                                        <div className="image-uploader-label">
                                            Drag & drop or select from your files
                                        </div>
                                    </label>
                                    <small id="image" className="form-text text-muted">
                                        Add Testimonial Image
                                    </small>
                                </div>
                            </div>
                        </div>
                        {success &&
                        <div className="alert alert-success" role="alert">
                            {successMessage}
                        </div>}
                        {error &&
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>}
                        <button className="btn pink-button form-button"
                                style={{marginTop: '5%', marginRight: '2%', width: '25%'}}>
                            {loading ?
                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                      aria-hidden="true"/>
                                : null
                            }
                            {action === 'Add New Testimonial' ? 'Add' : 'Save'}
                        </button>
                        <button className="btn white-button form-button" id=""
                                style={{marginTop: '5%', marginLeft: '2%', width: '25%'}}
                                onClick={this.actionClick}>
                            Cancel
                        </button>
                    </form>
                    : [
                        <h1 className="form-heading" key="heading"
                            style={{
                                marginBottom: '1%',
                                color: 'var(--primary-font-color)',
                                fontWeight: 'bold'
                            }}>Testimonials</h1>,
                        <p className="product-description" key="paragraph" style={{fontWeight: 'normal'}}>
                            Add / Remove / Edit Testimonials.
                        </p>,
                        <div key="testimonial-delete" className="action-container">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={'testimonialSelectAll'}
                                       checked={testimonials.length === deleteTestimonials.length}
                                       onChange={() => this.handleDeleteAllCheckBox()}/>
                                <label className="custom-control-label" htmlFor={'testimonialSelectAll'}>Select
                                    All</label>
                            </div>
                            <div className="action-button-container">
                                <button className="btn pink-border-button" onClick={this.delete}
                                        disabled={deleteTestimonials.length === 0}>
                                    Delete
                                </button>
                                <button className="btn pink-button" id="Add New Testimonial" onClick={this.actionClick}>
                                    Add
                                </button>
                            </div>
                        </div>,
                        <table className="w-100 portal-table" key="testimonial-table">
                            <thead>
                            <tr>
                                <th/>
                                <th>No</th>
                                <th>Name</th>
                                <th>Designation</th>
                                <th>Company Name</th>
                                <th>Message</th>
                                <th>Image</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {this.tableBody()}
                            </tbody>
                        </table>,
                    ]}
            </div>
        );
    };

    actionClick = (event, testimonial = null) => {
        if (!testimonial) event.preventDefault();
        this.setState({
            action: testimonial ? event : event.target.id,
            selectedTestimonial: testimonial,
            image: null,
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null
        });
    };

    formSubmit = (event) => {
        let {action, user, testimonials, selectedTestimonial} = this.state,
            endpoint;
        this.setState({loading: true});
        event.preventDefault();
        const data = new FormData();
        if (action === 'Add New Testimonial') {
            data.append('name', event.target.name.value);
            data.append('company_name', event.target.company_name.value);
            data.append('designation', event.target.designation.value);
            data.append('message', event.target.message.value);
            if (this.imageInput.current.files.length > 0) {
                data.append('image', this.imageInput.current.files[0]);
            }
            endpoint = '/admin/api/testimonial/';
        } else {
            data.append('name', selectedTestimonial.name);
            data.append('company_name', selectedTestimonial.company_name);
            data.append('designation', selectedTestimonial.designation);
            data.append('message', selectedTestimonial.message);
            if (selectedTestimonial.image) {
                data.append('image', selectedTestimonial.image);
            }
            endpoint = '/admin/api/testimonial/' + selectedTestimonial.id;
        }
        uploadFile(data, endpoint, user.token, action === 'Edit Testimonial').then(response => {
            if (response.status === 200) {
                if (action === 'Add New Testimonial') {
                    testimonials.push(response.data.message.data);
                } else {
                    testimonials[testimonials.indexOf(selectedTestimonial)] = response.data.message.data;
                }
                this.setState({
                    error: false,
                    errorMessage: null,
                    success: true,
                    successMessage: action === 'Add New Testimonial' ? 'Successfully created testimonial' : 'Successfully updated Testimonial',
                    testimonials: testimonials,
                    action: "",
                });
            } else {
                this.setState({
                    error: true,
                    errorMessage: response.data.message,
                    success: false,
                    successMessage: null,
                });
            }
            this.setState({loading: false});
        })
    }

    tableBody = () => {
        let body = [],
            {testimonials, deleteTestimonials} = this.state;
        if (testimonials) {
            testimonials.forEach((testimonial, index) => {
                body.push(
                    <tr key={index}>
                        <td>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input"
                                       id={'testimonialDelete' + testimonial.id}
                                       checked={deleteTestimonials.includes(testimonial.id)}
                                       onChange={(event) => this.handleDeleteCheckBox(testimonial.id)}/>
                                <label className="custom-control-label" htmlFor={'testimonialDelete' + testimonial.id}/>
                            </div>
                        </td>
                        <td>{index + 1}</td>
                        <td>
                            {testimonial.name}
                        </td>
                        <td>
                            {parse(testimonial.designation)}
                        </td>
                        <td>
                            {testimonial.company_name}
                        </td>
                        <td>
                            <div style={{
                                width: 300,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}>{testimonial.message}</div>
                        </td>
                        <td className="image-column">
                            {testimonial.profile_image ?
                                <img src={testimonial.profile_image} alt={testimonial.name}
                                     style={{width: '65px', height: '65px', borderRadius: '32px'}}/>
                                : null
                            }
                        </td>
                        <td className="action-column">
                            <button onClick={() => this.actionClick('Edit Testimonial', testimonial)}>Edit</button>
                        </td>
                    </tr>
                )
            });
        }
        return body;
    }

    handleDeleteCheckBox = async (id) => {
        let {deleteTestimonials} = this.state;
        this.setState({deleteTestimonials: handleDeleteCheckBox(id, deleteTestimonials)});
    };

    handleDeleteAllCheckBox() {
        let {deleteTestimonials, testimonials} = this.state;
        this.setState({deleteTestimonials: handleDeleteAllCheckBox(deleteTestimonials, testimonials)});
    }

    delete = () => {
        let {user, testimonials, deleteTestimonials} = this.state;
        deleteTestimonials.forEach((testimonial, index) => {
            deleteRequest('/admin/api/testimonial/' + testimonial, user.token).then(response => {
                if (response.status === 200) {
                    if (deleteTestimonials.length === index + 1) {
                        this.setState({
                            testimonials: testimonials.filter(function (testimonial) {
                                return !deleteTestimonials.includes(testimonial.id);
                            }),
                            deleteTestimonials: []
                        });
                        toast.success('Successfully deleted Testimonials', {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                } else {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
        });
    }

    handleChange(event, key) {
        let {selectedTestimonial, image} = this.state;
        if (selectedTestimonial) {
            if (key === 'image') {
                image = event.current.files[0];
                selectedTestimonial[key] = image;
            } else {
                selectedTestimonial[key] = event.target.value;
            }
            this.setState({selectedTestimonial: selectedTestimonial, image: image});
        } else {
            this.setState({image: event.current.files[0]});
        }
    }
}

export default Testimonial;
