import jwt from 'jwt-decode';

export const createUser = async (user) => {
    // user.time = new Date();
    sessionStorage.setItem('user', JSON.stringify(user));
};

export const getUser = async (redirect) => {
    let user = JSON.parse(sessionStorage.getItem('user')),
        decodedToken = jwt(user.token),
        currentDate = new Date();

    if (decodedToken.exp * 1000 < currentDate.getTime()) {
        await deleteUser();
        if (redirect) {
            window.location.href = '/login';
        }
    }

    // if (!(new Date(user.time) > currentDate.setHours(currentDate.getHours() - 2))) {
    //     await deleteUser();
    //     if (redirect) {
    //         window.location.href = '/login';
    //     }
    // }

    return JSON.parse(sessionStorage.getItem('user'));
};

export const deleteUser = async () => {
    sessionStorage.removeItem('user');
};
