import React, {Component} from 'react';

import Loading from "../components/Loading";

import aboutUsHeader from '../assets/images/about-us-header.jpg';
import chairman from '../assets/images/chairman.jpg';
import ceo from '../assets/images/ceo.jpg';

import '../assets/css/Home.css';
import '../assets/css/AboutUs.css';

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({loading: false});
        }, 2500);
    }

    render() {
        let {loading} = this.state;
        return (
            <>
                {loading ? <Loading/> : null}
                <div style={{display: loading ? 'none' : 'block'}}>
                    <div className="container home-header about-us-header text-center">
                        <h1 className='home-header-heading'>
                            How it started
                        </h1>
                        <p className='home-header-description about-us-header-description'>
                            Industrial experience spanning decades in software off-shoring had proven how success,
                            growth
                            and sustainablity in business are powerered by hard work, trust, and responsibility. This
                            unique insight became the core philosophy that launched Excelly as a collective of
                            technology
                            teams who guide our clients and their firms toward accelerated productivity and improved
                            performance.
                        </p>
                        <img src={aboutUsHeader} alt="About Us Header" className="about-us-header-image"/>
                    </div>
                    <div className="founders-container">
                        <div className="container">
                            <h2 className="container-heading" style={{margin: 0}}>The Founders</h2>
                            <div className="row" style={{width: '100%', margin: 0}}>
                                <div className="col" style={{position: 'relative', padding: 0}}>
                                    <div className="about-us-description">
                                        <p className="product-description founders-description">
                                            With over 26 years of experience in consulting, managing and founding
                                            fintech
                                            companies and having worked with Sri Lankan off-shore tech teams, Norway
                                            native
                                            Thomas Thorsen quickly discovered how Sri Lankan values resonated with his
                                            own
                                            principles of hard work, trust and responsibility.<br/><br/>
                                            Establishing, expanding and managing globally distributed software
                                            development
                                            teams through pragmatic and diplomatic leadership for 15 years, Sri
                                            Lankan
                                            native Sandun Weerasinghe developed a deep appreciation of how teamwork is
                                            just
                                            as vital as educational background and technical know-how.<br/><br/>
                                            A few years ago, Sandun and Thomas embarked on a professional journey driven
                                            by
                                            their close friendship that energized Excelly.
                                        </p>
                                    </div>
                                </div>
                                <div className="col d-flex founder-images-container">
                                    <div style={{position: 'relative'}}>
                                        <img src={chairman} alt="Thomas Thorsen"/>
                                        <div className="overlay">
                                            <h4>Thomas Thorsen</h4>
                                            <p>Chairman</p>
                                        </div>
                                    </div>
                                    <div style={{position: 'relative'}}>
                                        <img src={ceo} alt="Sandun Weerasinghe"/>
                                        <div className="overlay">
                                            <h4>Sandun Weerasinghe</h4>
                                            <p>Chief Executive Officer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="founders-container" style={{backgroundColor: 'white'}}>
                        <div className="container">
                            <div className="row" style={{width: '100%', margin: 0}}>
                                <div className="col" style={{padding: 0}}>
                                    <h2 className="container-heading">The way forward</h2>
                                </div>
                                <div className="col"/>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <p className="product-description">
                                        With business requirements and technological advancements both in constant flux,
                                        software solutions should be tailored to focus on how best to fulfill every
                                        objective of every client.<br/><br/>
                                        For the transcontinental team at Excelly, your professional strategies and
                                        personal
                                        targets are streamlined in order to
                                        empower your business for an accelerated path to success.
                                    </p>
                                </div>
                                <div className="col" style={{marginLeft: '20%'}}>
                                    <p className="product-description">
                                        Excelly also strives to improve connectivity so that the workplace can be
                                        maintained
                                        as a reliable, friendly, and secure environment for all employees whether they
                                        are
                                        based in Norway or Sri Lanka.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default AboutUs;
