import React, {Component} from 'react';
import {toast} from 'react-toastify';

import {deleteRequest, uploadFile} from "../../routes/Routes";
import {getUser} from "../../auth/Auth";
import {handleDeleteAllCheckBox, handleDeleteCheckBox, loadAdminBlogAuthors} from "./Common";

import addImage from '../../assets/images/add.png';

class BlogAuthor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            action: "",
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
            blogAuthors: [],
            deleteBlogAuthors: [],
            selectedBlogAuthor: null,
            image: null,
        };
        this.imageInput = React.createRef();
    }

    componentDidMount() {
        getUser(true).then(data => {
            this.setState({user: data});
            loadAdminBlogAuthors(data.token).then(data => {
                this.setState({blogAuthors: data});
            }).catch(() => null);
        }).catch(() => null);
    }

    render() {
        let {
            loading,
            action,
            error,
            errorMessage,
            success,
            successMessage,
            blogAuthors,
            deleteBlogAuthors,
            selectedBlogAuthor,
            image
        } = this.state;
        return (
            <div>
                {action === 'Add New Blog Author' || action === 'Edit Blog Author' ?
                    <form onSubmit={this.formSubmit} className="portal-form">
                        {window.scrollTo(0, 0)}
                        <h1 className="form-heading" key="heading"
                            style={{
                                marginBottom: '1%',
                                color: 'var(--primary-font-color)',
                                fontWeight: 'bold'
                            }}>{action}</h1>
                        <p className="product-description" style={{fontWeight: 'normal'}}>
                            Add / Remove / Edit Blog Authors.
                        </p>
                        <div className="form-group">
                            <label htmlFor="name" className="form-label">Author</label>
                            {selectedBlogAuthor ?
                                <input className="form-control" type="text" placeholder='Name' id="name" required
                                       value={selectedBlogAuthor.name}
                                       onChange={(event) => this.handleChange(event, 'name')}/>
                                : <input className="form-control" type="text" placeholder='Name' id="name" required/>
                            }
                        </div>
                        {image ?
                            <img src={URL.createObjectURL(image)} style={{marginBottom: '5%'}}/>
                            : selectedBlogAuthor ?
                                <img src={selectedBlogAuthor.profile_image} style={{marginBottom: '5%'}}/>
                                : null
                        }
                        <div className="custom-file">
                            <input type="file" className="custom-file-input" id="blogAuthorImage" accept="image/*"
                                   ref={this.imageInput}
                                   onChange={(event) => this.handleChange(this.imageInput, 'image')}/>
                            <label className="custom-file-label" htmlFor="blogAuthorImage">
                                <img src={addImage} alt="Add Icon"/>
                                <div className="image-uploader-label">
                                    Drag & drop or select from your files
                                </div>
                            </label>
                            <small id="image" className="form-text text-muted">
                                Add Blog Author Image
                            </small>
                        </div>
                        {success &&
                        <div className="alert alert-success" role="alert">
                            {successMessage}
                        </div>}
                        {error &&
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>}
                        <button className="btn pink-button form-button" style={{marginTop: '5%', marginRight: '2%', width: '40%'}} >
                            {loading ?
                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                      aria-hidden="true"/>
                                : null
                            }
                            {action === 'Add New Blog Author' ? 'Add' : 'Save'}
                        </button>
                        <button className="btn white-button form-button" id="" style={{marginTop: '5%', marginLeft: '2%', width: '40%'}}
                                onClick={this.actionClick}>
                            Cancel
                        </button>
                    </form>
                    : [
                        <h1 className="form-heading" key="heading"
                            style={{
                                marginBottom: '1%',
                                color: 'var(--primary-font-color)',
                                fontWeight: 'bold'
                            }}>Blog Authors</h1>,
                        <p className="product-description" key="paragraph" style={{fontWeight: 'normal'}}>
                            Add / Remove / Edit Blog Authors.
                        </p>,
                        <div key="blog-author-delete" className="action-container">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={'blogAuthorSelectAll'}
                                       checked={blogAuthors.length === deleteBlogAuthors.length}
                                       onChange={() => this.handleDeleteAllCheckBox()}/>
                                <label className="custom-control-label" htmlFor={'blogAuthorSelectAll'}>Select
                                    All</label>
                            </div>
                            <div className="action-button-container">
                                <button className="btn pink-border-button" onClick={this.delete}
                                        disabled={deleteBlogAuthors.length === 0}>
                                    Delete
                                </button>
                                <button className="btn pink-button" id="Add New Blog Author" onClick={this.actionClick}>
                                    Add
                                </button>
                            </div>
                        </div>,
                        <table className="w-100 portal-table" key="blog-author-table">
                            <thead>
                            <tr>
                                <th/>
                                <th>No</th>
                                <th>Name</th>
                                <th>Image</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {this.tableBody()}
                            </tbody>
                        </table>,
                    ]}
            </div>
        );
    };

    actionClick = (event, blogAuthor = null) => {
        if (!blogAuthor) event.preventDefault();
        this.setState({
            action: blogAuthor ? event : event.target.id,
            selectedBlogAuthor: blogAuthor,
            image: null,
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null
        });
    };

    formSubmit = (event) => {
        let {action, user, blogAuthors, selectedBlogAuthor} = this.state,
            endpoint;
        this.setState({loading: true});
        event.preventDefault();
        const data = new FormData();
        if (action === 'Add New Blog Author') {
            data.append('name', event.target.name.value);
            if (this.imageInput.current.files.length > 0) {
                data.append('image', this.imageInput.current.files[0]);
            }
            endpoint = '/admin/api/blog_author/';
        } else {
            data.append('name', selectedBlogAuthor.name);
            if (selectedBlogAuthor.image) {
                data.append('image', selectedBlogAuthor.image);
            }
            endpoint = '/admin/api/blog_author/' + selectedBlogAuthor.id;
        }
        uploadFile(data, endpoint, user.token, action === 'Edit Blog Author').then(response => {
            if (response.status === 200) {
                if (action === 'Add New Blog Author') {
                    blogAuthors.push(response.data.message.data);
                } else {
                    blogAuthors[blogAuthors.indexOf(selectedBlogAuthor)] = response.data.message.data;
                }
                this.setState({
                    error: false,
                    errorMessage: null,
                    success: true,
                    successMessage: action === 'Add New Blog Author' ? 'Successfully created Blog Author' : 'Successfully updated Blog Author',
                    blogAuthors: blogAuthors,
                    action: "",
                });
            } else {
                this.setState({
                    error: true,
                    errorMessage: response.data.message,
                    success: false,
                    successMessage: null,
                });
            }
            this.setState({loading: false});
        })
    }

    tableBody = () => {
        let body = [],
            {blogAuthors, deleteBlogAuthors} = this.state;
        if (blogAuthors) {
            blogAuthors.forEach((blogAuthor, index) => {
                body.push(
                    <tr key={index}>
                        <td>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input"
                                       id={'blogAuthorDelete' + blogAuthor.id}
                                       checked={deleteBlogAuthors.includes(blogAuthor.id)}
                                       onChange={(event) => this.handleDeleteCheckBox(blogAuthor.id)}/>
                                <label className="custom-control-label" htmlFor={'blogAuthorDelete' + blogAuthor.id}/>
                            </div>
                        </td>
                        <td>{index + 1}</td>
                        <td>
                            {blogAuthor.name}
                        </td>
                        <td className="image-column">
                            {blogAuthor.profile_image ?
                                <img src={blogAuthor.profile_image} alt={blogAuthor.name}
                                     style={{width: '65px', height: '65px', borderRadius: '32px'}}/>
                                : null
                            }
                        </td>
                        <td className="action-column">
                            <button onClick={() => this.actionClick('Edit Blog Author', blogAuthor)}>Edit</button>
                        </td>
                    </tr>
                )
            });
        }
        return body;
    }

    handleDeleteCheckBox = async (id) => {
        let {deleteBlogAuthors} = this.state;
        this.setState({deleteBlogAuthors: handleDeleteCheckBox(id, deleteBlogAuthors)});
    };

    handleDeleteAllCheckBox() {
        let {deleteBlogAuthors, blogAuthors} = this.state;
        this.setState({deleteBlogAuthors: handleDeleteAllCheckBox(deleteBlogAuthors, blogAuthors)});
    }

    delete = () => {
        let {user, blogAuthors, deleteBlogAuthors} = this.state;
        deleteBlogAuthors.forEach((blogAuthor, index) => {
            deleteRequest('/admin/api/blog_author/' + blogAuthor, user.token).then(response => {
                if (response.status === 200) {
                    if (deleteBlogAuthors.length === index + 1) {
                        this.setState({
                            blogAuthors: blogAuthors.filter(function (blogAuthor) {
                                return !deleteBlogAuthors.includes(blogAuthor.id);
                            }),
                            deleteBlogAuthors: []
                        });
                        toast.success('Successfully deleted Blog Author', {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                } else {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
        });
    }

    handleChange(event, key) {
        let {selectedBlogAuthor, image} = this.state;
        if (selectedBlogAuthor) {
            if (key === 'image') {
                image = event.current.files[0];
                selectedBlogAuthor[key] = image;
            } else {
                selectedBlogAuthor[key] = event.target.value;
            }
            this.setState({selectedBlogAuthor: selectedBlogAuthor, image: image});
        } else {
            this.setState({image: event.current.files[0]});
        }
    }
}

export default BlogAuthor;
