import React, {Component} from 'react';
import {toast} from 'react-toastify';

import {deleteRequest, uploadFile} from "../../routes/Routes";
import {getUser} from "../../auth/Auth";
import {handleDeleteAllCheckBox, handleDeleteCheckBox, loadAdminCaseStudies} from "./Common";

import addImage from '../../assets/images/add.png';
import parse from "html-react-parser";

class CaseStudy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            action: "",
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
            caseStudies: [],
            deleteCaseStudies: [],
            selectedCaseStudy: null,
            image: null,
        };
        this.imageInput = React.createRef();
    }

    componentDidMount() {
        getUser(true).then(data => {
            this.setState({user: data});
            loadAdminCaseStudies(data.token).then(data => {
                this.setState({caseStudies: data});
            }).catch(() => null);
        }).catch(() => null);
    }

    render() {
        let {
            loading,
            action,
            error,
            errorMessage,
            success,
            successMessage,
            caseStudies,
            deleteCaseStudies,
            selectedCaseStudy,
            image
        } = this.state;
        return (
            <div>
                {window.scrollTo(0, 0)}
                {action === 'Add New Case Study' || action === 'Edit Case Study' ?
                    <form onSubmit={this.formSubmit} className="portal-form" style={{width: '60%'}}>
                        <h1 className="form-heading" key="heading"
                            style={{
                                marginBottom: '1%',
                                color: 'var(--primary-font-color)',
                                fontWeight: 'bold'
                            }}>{action}</h1>
                        <p className="product-description" style={{fontWeight: 'normal'}}>
                            Add / Remove / Edit Case Studies.
                        </p>
                        <div className="row" style={{width: '100%'}}>
                            <div className="form-group col">
                                <label htmlFor="title" className="form-label">Title</label>
                                {selectedCaseStudy ?
                                    <input className="form-control" type="text" placeholder='Title' id="title" required
                                           value={selectedCaseStudy.title}
                                           onChange={(event) => this.handleChange(event, 'title')}/>
                                    :
                                    <input className="form-control" type="text" placeholder='Title' id="title"
                                           required/>
                                }
                            </div>
                            <div className="form-group col">
                                <label htmlFor="technology_list" className="form-label">Technology Stack</label>
                                {selectedCaseStudy ?
                                    <input className="form-control" type="text" placeholder='Technology Stack'
                                           id="technology_list" required
                                           value={selectedCaseStudy.technology_list}
                                           onChange={(event) => this.handleChange(event, 'technology_list')}/>
                                    : <input className="form-control" type="text" placeholder='Technology Stack'
                                             id="technology_list" required/>
                                }
                                <small id="technology_stack" className="form-text text-muted">
                                    Separate using comma "," ie: .NET, Microsoft Azure
                                </small>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="description" className="form-label">Description</label>
                            {selectedCaseStudy ?
                                <textarea className="form-control" id="description" placeholder="Description" required
                                          value={selectedCaseStudy.description}
                                          onChange={(event) => this.handleChange(event, 'description')}/>
                                :
                                <textarea className="form-control" id="description" placeholder="Description" required/>
                            }
                        </div>
                        {image ?
                            <img src={URL.createObjectURL(image)} style={{marginBottom: '5%'}}/>
                            : selectedCaseStudy ?
                                <img src={selectedCaseStudy.image} style={{marginBottom: '5%'}}/>
                                : null
                        }
                        <div className="custom-file">
                            <input type="file" className="custom-file-input" id="caseStudyImage" accept="image/*"
                                   ref={this.imageInput}
                                   onChange={(event) => this.handleChange(this.imageInput, 'image')}/>
                            <label className="custom-file-label" htmlFor="caseStudyImage">
                                <img src={addImage} alt="Add Icon"/>
                                <div className="image-uploader-label">
                                    Drag & drop or select from your files
                                </div>
                            </label>
                            <small id="image" className="form-text text-muted">
                                Add Case Study Image
                            </small>
                        </div>
                        {success &&
                        <div className="alert alert-success" role="alert">
                            {successMessage}
                        </div>}
                        {error &&
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>}
                        <button className="btn pink-button form-button" style={{marginTop: '5%', marginRight: '2%', width: '25%'}}>
                            {loading ?
                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                      aria-hidden="true"/>
                                : null
                            }
                            {action === 'Add New Case Study' ? 'Add' : 'Save'}
                        </button>
                        <button className="btn white-button form-button" id="" style={{marginTop: '5%', marginLeft: '2%', width: '25%'}}
                                onClick={this.actionClick}>
                            Cancel
                        </button>
                    </form>
                    : [
                        <h1 className="form-heading" key="heading"
                            style={{
                                marginBottom: '1%',
                                color: 'var(--primary-font-color)',
                                fontWeight: 'bold'
                            }}>Case Studies</h1>,
                        <p className="product-description" key="paragraph" style={{fontWeight: 'normal'}}>
                            Add / Remove / Edit Case Studies.
                        </p>,
                        <div key="case-study-delete" className="action-container">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={'caseStudySelectAll'}
                                       checked={caseStudies.length === deleteCaseStudies.length}
                                       onChange={() => this.handleDeleteAllCheckBox()}/>
                                <label className="custom-control-label" htmlFor={'caseStudySelectAll'}>Select
                                    All</label>
                            </div>
                            <div className="action-button-container">
                                <button className="btn pink-border-button" onClick={this.delete}
                                        disabled={deleteCaseStudies.length === 0}>
                                    Delete
                                </button>
                                <button className="btn pink-button" id="Add New Case Study" onClick={this.actionClick}>
                                    Add
                                </button>
                            </div>
                        </div>,
                        <table className="w-100 portal-table" key="case-study-table">
                            <thead>
                            <tr>
                                <th/>
                                <th>No</th>
                                <th>Name</th>
                                <th>Technology Stack</th>
                                <th>Description</th>
                                <th>Image</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {this.tableBody()}
                            </tbody>
                        </table>,
                    ]}
            </div>
        );
    };

    actionClick = (event, caseStudy = null) => {
        if (!caseStudy) event.preventDefault();
        this.setState({
            action: caseStudy ? event : event.target.id,
            selectedCaseStudy: caseStudy,
            image: null,
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null
        });
    };

    formSubmit = (event) => {
        let {action, user, caseStudies, selectedCaseStudy} = this.state,
            endpoint;
        this.setState({loading: true});
        event.preventDefault();
        const data = new FormData();
        if (action === 'Add New Case Study') {
            data.append('title', event.target.name.value);
            data.append('technology_list', event.target.technology_list.value);
            data.append('description', event.target.description.value);
            data.append('company_name', "");
            if (this.imageInput.current.files.length > 0) {
                data.append('image', this.imageInput.current.files[0]);
            }
            endpoint = '/admin/api/case_study/';
        } else {
            data.append('title', selectedCaseStudy.title);
            data.append('technology_list', selectedCaseStudy.technology_list);
            data.append('description', selectedCaseStudy.description);
            data.append('company_name', "");
            if (selectedCaseStudy.image) {
                data.append('image', selectedCaseStudy.image);
            }
            endpoint = '/admin/api/case_study/' + selectedCaseStudy.id;
        }
        uploadFile(data, endpoint, user.token, action === 'Edit Case Study').then(response => {
            if (response.status === 200) {
                if (action === 'Add New Case Study') {
                    caseStudies.push(response.data.message.data);
                } else {
                    caseStudies[caseStudies.indexOf(selectedCaseStudy)] = response.data.message.data;
                }
                this.setState({
                    error: false,
                    errorMessage: null,
                    success: true,
                    successMessage: action === 'Add New Case Study' ? 'Successfully created Case Study' : 'Successfully updated Case Study',
                    caseStudies: caseStudies,
                    action: "",
                });
            } else {
                this.setState({
                    error: true,
                    errorMessage: response.data.message,
                    success: false,
                    successMessage: null,
                });
            }
            this.setState({loading: false});
        })
    }

    tableBody = () => {
        let body = [],
            {caseStudies, deleteCaseStudies} = this.state;
        if (caseStudies) {
            caseStudies.forEach((caseStudy, index) => {
                body.push(
                    <tr key={index}>
                        <td>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input"
                                       id={'caseStudyDelete' + caseStudy.id}
                                       checked={deleteCaseStudies.includes(caseStudy.id)}
                                       onChange={(event) => this.handleDeleteCheckBox(caseStudy.id)}/>
                                <label className="custom-control-label" htmlFor={'caseStudyDelete' + caseStudy.id}/>
                            </div>
                        </td>
                        <td>{index + 1}</td>
                        <td>
                            {caseStudy.title}
                        </td>
                        <td>
                            {parse(caseStudy.technology_list)}
                        </td>
                        <td>
                            <div style={{
                                width: 300,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}>{caseStudy.description}</div>
                        </td>
                        <td className="image-column">
                            {caseStudy.image ?
                                <img src={caseStudy.image} alt={caseStudy.name}
                                     style={{width: '65px', height: '65px'}}/>
                                : null
                            }
                        </td>
                        <td className="action-column">
                            <button onClick={() => this.actionClick('Edit Case Study', caseStudy)}>Edit</button>
                        </td>
                    </tr>
                )
            });
        }
        return body;
    }

    handleDeleteCheckBox = async (id) => {
        let {deleteCaseStudies} = this.state;
        this.setState({deleteCaseStudies: handleDeleteCheckBox(id, deleteCaseStudies)});
    };

    handleDeleteAllCheckBox() {
        let {deleteCaseStudies, caseStudies} = this.state;
        this.setState({deleteCaseStudies: handleDeleteAllCheckBox(deleteCaseStudies, caseStudies)});
    }

    delete = () => {
        let {user, caseStudies, deleteCaseStudies} = this.state;
        deleteCaseStudies.forEach((caseStudy, index) => {
            deleteRequest('/admin/api/case_study/' + caseStudy, user.token).then(response => {
                if (response.status === 200) {
                    if (deleteCaseStudies.length === index + 1) {
                        this.setState({
                            caseStudies: caseStudies.filter(function (caseStudy) {
                                return !deleteCaseStudies.includes(caseStudy.id);
                            }),
                            deleteCaseStudies: []
                        });
                        toast.success('Successfully deleted Case Studies', {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                } else {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
        });
    }

    handleChange(event, key) {
        let {selectedCaseStudy, image} = this.state;
        if (selectedCaseStudy) {
            if (key === 'image') {
                image = event.current.files[0];
                selectedCaseStudy[key] = image;
            } else {
                selectedCaseStudy[key] = event.target.value;
            }
            this.setState({selectedCaseStudy: selectedCaseStudy, image: image});
        } else {
            this.setState({image: event.current.files[0]});
        }
    }
}

export default CaseStudy;
