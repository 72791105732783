import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {isMobile, isTablet} from 'react-device-detect';

import {deleteUser, getUser} from "../auth/Auth";

import logo from '../assets/images/logo.png';

import '../assets/css/Header.css';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {user: null};
    }

    componentDidMount() {
        getUser().then(data => {
            this.setState({user: data});
        }).catch(() => null);
    }

    render() {
        let {user} = this.state;
        return (
            <header className="fixed-top navbar-header">
                <nav className="navbar navbar-expand-lg container">
                    <div className="container-fluid">
                        <NavLink className="navbar-brand" to="/" data-toggle={isMobile || isTablet ? "collapse" : ""}
                                 data-target=".navbar-collapse.show"
                                 onClick={() => document.getElementsByClassName('navbar-toggler')[0].setAttribute('aria-expanded', 'false')}>
                            <img src={logo} alt="Logo"/>
                        </NavLink>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"/>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mx-auto"/>
                            <ul className="navbar-nav mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/about-us" activeClassName="is-active"
                                             data-toggle={isMobile || isTablet ? "collapse" : ""}
                                             data-target="#navbarSupportedContent"
                                             onClick={() => document.getElementsByClassName('navbar-toggler')[0].setAttribute('aria-expanded', 'false')}>
                                        About
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/services" activeClassName="is-active"
                                             data-toggle={isMobile || isTablet ? "collapse" : ""}
                                             data-target="#navbarSupportedContent"
                                             onClick={() => document.getElementsByClassName('navbar-toggler')[0].setAttribute('aria-expanded', 'false')}>
                                        Services
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/case-studies" activeClassName="is-active"
                                             data-toggle={isMobile || isTablet ? "collapse" : ""}
                                             data-target="#navbarSupportedContent"
                                             onClick={() => document.getElementsByClassName('navbar-toggler')[0].setAttribute('aria-expanded', 'false')}>
                                        Case Studies
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/careers" activeClassName="is-active"
                                             data-toggle={isMobile || isTablet ? "collapse" : ""}
                                             data-target="#navbarSupportedContent"
                                             onClick={() => document.getElementsByClassName('navbar-toggler')[0].setAttribute('aria-expanded', 'false')}>
                                        Careers
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/blog" activeClassName="is-active"
                                             data-toggle={isMobile || isTablet ? "collapse" : ""}
                                             data-target="#navbarSupportedContent"
                                             onClick={() => document.getElementsByClassName('navbar-toggler')[0].setAttribute('aria-expanded', 'false')}>
                                        Blog
                                    </NavLink>
                                </li>
                                {user && <li className="nav-item dropdown">
                                    <NavLink className="nav-link dropdown-toggle" to="#" role="button"
                                             data-bs-toggle="dropdown" aria-expanded="false">
                                        Hi, <span
                                        style={{textTransform: 'capitalize'}}>{user.first_name} {user.last_name}</span>
                                    </NavLink>
                                    <ul className="dropdown-menu">
                                        <li><NavLink className="dropdown-item" to="/portal"
                                                     data-toggle={isMobile || isTablet ? "collapse" : ""}
                                                     data-target="#navbarSupportedContent">Portal</NavLink></li>
                                        <li>
                                            <hr className="dropdown-divider"/>
                                        </li>
                                        <li>
                                            <NavLink className="dropdown-item" to="/login" onClick={this.logout}
                                                     data-toggle={isMobile || isTablet ? "collapse" : ""}
                                                     data-target="#navbarSupportedContent">
                                                Sign out of <span
                                                style={{textTransform: 'capitalize'}}>{user.name}</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>}
                            </ul>
                            <ul className="navbar-nav mx-auto"/>
                            <ul className="navbar-nav d-flex">
                                <li className="nav-item">
                                    <button className="btn white-button">
                                        <a href="mailto: hello@excelly.io" className="nav-link">Get in touch with us</a>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }

    logout = () => {
        deleteUser().then(() => {
            this.setState({user: null});
        }).catch(() => null);
    };
}

export default Header;
