import {getRequest} from "../routes/Routes";
import {toast} from 'react-toastify';

export const loadTestimonials = async () => {
    let testimonials = [];
    await getRequest('/api/testimonial/').then(response => {
        if (response.status === 200) {
            testimonials = response.data.data;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return testimonials;
};

export const loadLimitedTestimonials = async (limit) => {
    let testimonials = [];
    await getRequest('/api/testimonial/limit/' + limit).then(response => {
        if (response.status === 200) {
            testimonials = response.data.data;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return testimonials;
};

export const loadCaseStudies = async () => {
    let caseStudies = [];
    await getRequest('/api/case_study/').then(response => {
        if (response.status === 200) {
            caseStudies = response.data.data;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return caseStudies;
};

export const loadLimitedCaseStudies = async (limit) => {
    let caseStudies = [];
    await getRequest('/api/case_study/limit/' + limit).then(response => {
        if (response.status === 200) {
            caseStudies = response.data.data;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return caseStudies;
};

export const loadBlogs = async () => {
    let blogs = [];
    await getRequest('/api/blog/').then(response => {
        if (response.status === 200) {
            blogs = response.data.data;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return blogs;
};

export const loadBlog = async (slug) => {
    let blog = null;
    await getRequest('/api/blog/' + slug).then(response => {
        if (response.status === 200) {
            blog = response.data.data;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return blog;
};

export const loadClientLogos = async () => {
    let clientLogos = [];
    await getRequest('/api/client_logo/').then(response => {
        if (response.status === 200) {
            clientLogos = response.data.data;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return clientLogos;
};
