import {getRequest} from "../../routes/Routes";
import {toast} from 'react-toastify';

export const handleDeleteCheckBox = (id, deleteArray) => {
    if (deleteArray.includes(id)) {
        deleteArray = deleteArray.filter(function (element) {
            return element !== id
        });
    } else {
        deleteArray.push(id);
    }
    return deleteArray;
};

export const handleDeleteAllCheckBox = (deleteArray, listArray) => {
    if (deleteArray.length === listArray.length) {
        deleteArray = [];
    } else {
        listArray.map(function (element) {
            if (!deleteArray.includes(element.id)) {
                deleteArray.push(element.id);
            }
        });
    }
    return deleteArray;
}

export const loadAdminTestimonials = async (token) => {
    let testimonials = [];
    await getRequest('/admin/api/testimonial/', token).then(response => {
        if (response.status === 200) {
            testimonials = response.data.data;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return testimonials;
};

export const loadAdminCaseStudies = async (token) => {
    let caseStudies = [];
    await getRequest('/admin/api/case_study/', token).then(response => {
        if (response.status === 200) {
            caseStudies = response.data.data;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return caseStudies;
};

export const loadAdminBlogs = async (token) => {
    let blogs = [];
    await getRequest('/admin/api/blog/post', token).then(response => {
        if (response.status === 200) {
            blogs = response.data.data;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return blogs;
};

export const loadAdminBlogAuthors = async (token) => {
    let blogAuthors = [];
    await getRequest('/admin/api/blog_author/', token).then(response => {
        if (response.status === 200) {
            blogAuthors = response.data.data;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return blogAuthors;
};

export const loadAdminClientLogos = async (token) => {
    let clientLogos = [];
    await getRequest('/admin/api/client_logo/', token).then(response => {
        if (response.status === 200) {
            clientLogos = response.data.data;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return clientLogos;
};
