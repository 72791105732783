import {Component} from "react";
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ScrollToTop from './components/ScrollToTop';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./views/Home";
import AboutUs from "./views/AboutUs";
import Services from "./views/Services";
import Careers from "./views/Careers";
import PrivacyPolicy from "./views/PrivacyPolicy";
import CaseStudies from "./views/CaseStudies";
import Blogs from "./views/Blogs";
import Blog from "./views/Blog";
import Login from "./views/Login";
import Portal from "./views/Portal";

import {getUser} from "./auth/Auth";

import './App.css';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {user: undefined};
    }

    componentDidMount() {
        getUser().then((data) => {
            this.setState({user: data});
        }).catch(() => {
            this.setState({user: null});
        });
    }

    render() {
        const {user} = this.state;
        if (user !== undefined) {
            return (
                <Router>
                    <ScrollToTop>
                        <div id="header">
                            <Header/>
                        </div>
                        <div id="body">
                            <Routes>
                                <Route exact path='/' element={<Home/>}/>
                                <Route path='/about-us' element={<AboutUs/>}/>
                                <Route path='/services' element={<Services/>}/>
                                <Route path='/careers' element={<Careers/>}/>
                                <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
                                <Route path='/case-studies' element={<CaseStudies/>}/>
                                <Route path='/blog' element={<Blogs/>}/>
                                <Route path='/blog/content/:slug' element={<Blog/>}/>
                                <Route path='/login' element={<Login/>}/>
                                <Route path='/portal' element={user ? <Portal/> : <Navigate to="/login"/>}/>
                                <Route path='*' element={<Navigate to="/"/>}/>
                            </Routes>
                            <ToastContainer
                                position="bottom-center"
                                autoClose={3000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover/>
                        </div>
                        <div id="footer">
                            <Footer/>
                        </div>
                    </ScrollToTop>
                </Router>
            );
        } else {
            return null;
        }
    }
}

export default App;
