import React, {Component} from 'react';

import {createUser} from "../auth/Auth";
import {postRequest} from "../routes/Routes";

import '../assets/css/Home.css';
import '../assets/css/Portal.css';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {error: false, errorMessage: null, loading: false};
    }

    render() {
        let {error, errorMessage, loading} = this.state;
        return (
            <div className="container home-header text-center">
                <h1 className='home-header-heading'>
                    Login
                </h1>
                <div className="login-component container">
                    <form onSubmit={this.loginFunction}>
                        <div className="form-group">
                            <label htmlFor="email">Email Address</label>
                            <input type="email" className="form-control" id="email" placeholder="john@gmail.com"
                                   required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input className="form-control" type="password" id="password" minLength="8"
                                   placeholder="Password" required/>
                        </div>
                        {error &&
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>}
                        <button className="btn blue-button login-button">
                            {loading ?
                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                      aria-hidden="true"/>
                                : null
                            }
                            Login
                        </button>
                    </form>
                </div>
            </div>
        )
    }

    loginFunction = (event) => {
        this.setState({loading: true});
        event.preventDefault();
        let values = {
            email: event.target.email.value,
            password: event.target.password.value,
            endpoint: '/admin/api/auth/login'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                createUser(response.data.data).then(() => {
                    this.setState({error: false, errorMessage: null});
                    window.location.href = '/portal';
                });
            } else {
                this.setState({error: true, errorMessage: response.data.message});
            }
            this.setState({loading: false});
        });
    }
}

export default Login;
